/* spinner styling */
.lds-ripple {
	position: relative;
	width: 80px;
	height: 80px;
}
.lds-ripple div {
	position: absolute;
	border: 4px solid rgb(212, 96, 96);
	opacity: 1;
	border-radius: 50%;
	animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
	animation-delay: -0.5s;
}
@keyframes lds-ripple {
	0% {
		top: 36px;
		left: 36px;
		width: 0;
		height: 0;
		opacity: 1;
	}
	100% {
		top: 0px;
		left: 0px;
		width: 72px;
		height: 72px;
		opacity: 0;
	}
}

/* Navbar styling */
.custom-nav {
	height: 60px;
}

.active-header-link {
	color: #ffffff;
}
.inactive-header-link {
	color: #999999;
}
.action-icons {
	color: #ffffff;
	cursor: pointer;
}

/* Dashboard page */
.dashboard-nav {
	height: 70px;
	background-color: #ffffff;
	font-size: 14px !important;
	font-weight: 400;
	color: #666666;
}

.dashboard-nav-links .active {
	color: #000000;
	font-weight: 700;
	border-bottom: 4px solid #f26925;
}

.dashboard-page {
	background-color: #e5e5e5;
}

/* Rodal CSS */
.rodal,
.rodal-mask {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	position: fixed !important;
	padding: 10px;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow-y: auto;
	overflow-x: hidden;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.rodal-dialog {
	display: flex;
	margin: auto;
	height: auto !important;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	background-color: #fff;
	font-family: inherit;
	font-size: 1rem;
	padding: 1.25em;
	border-radius: 0.3125em;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	position: relative !important;
	width: 32em;
	max-width: 100%;
}

.tooltip .tooltip-text {
	visibility: hidden;
	text-align: center;
	padding: 2px 6px;
	position: absolute;
	z-index: 100;
}
.tooltip:hover .tooltip-text {
	visibility: visible;
	background-color: #999999;
	color: #fff;
	border-radius: 12px;
	margin-top: 10px;
}

.customize-date {
	height: 40px;
	width: 200px;
	border-radius: 4px;
	padding: 4px;
	font-size: 12px;
	border: 1px solid #e2e8f0;
	background-color: #f8fafd;
	outline-color: #e2e8f0;
}

.react-datepicker-popper {
	width: 400px;
}

/* Announcement recipient style */

.notification-container {
	min-height: 200px;
	width: 300px;
	margin-top: 30px;
	position: absolute;
	padding: 15px;
	border-radius: 4px;
	z-index: 100;
}

/* 
phone input
*/
.react-tel-input .form-control {
	position: relative;
	font-size: 14px;
	letter-spacing: 0.01rem;
	margin-top: 0 !important;
	margin-bottom: 0 !important;
	padding-left: 8px !important;
	margin-left: 60px !important;
	background: #f8fafd !important;
	border: 1px solid #cacaca;
	border-radius: 5px;
	line-height: 25px;
	height: 44px !important;
	width: 210px !important;
	outline: none;
}

.react-tel-input .flag-dropdown {
	position: absolute;
	top: 0;
	bottom: 0;
	padding: 5px !important;
	background-color: #f5f5f5;
	border: 1px solid #cacaca;
	border-radius: 4px !important;
}

.react-tel-input .country-list .search-box {
	border: 1px solid #cacaca;
	border-radius: 3px;
	font-size: 15px;
	line-height: 15px;
	margin-left: 6px;
	padding: 3px 8px 5px;
	outline: none;
	width: 90% !important;
}
